import { ReactComponent as DashboardIcon } from 'assets/icons/side-menu/dashboard.svg';
import { ReactComponent as AssetsIcon } from 'assets/icons/side-menu/assets.svg';
import { ReactComponent as FinanceIcon } from 'assets/icons/side-menu/finance.svg';
import { ReactComponent as SubscribersIcon } from 'assets/icons/side-menu/subscribers.svg';
import { ReactComponent as BroadcastsIcon } from 'assets/icons/side-menu/broadcasts.svg';
import { ReactComponent as StatisticsIcon } from 'assets/icons/side-menu/statistics.svg';
import { ReactComponent as ConversationsIcon } from 'assets/icons/side-menu/conversations.svg';
import { ReactComponent as NoAvatarIcon } from 'assets/icons/side-menu/no-avatar.svg';
import { ReactComponent as ImportIcon } from 'assets/icons/side-menu/import.svg';
import { ReactComponent as FinanceDocumentsIcon } from 'assets/icons/side-menu/finance-documents.svg';
import { ReactComponent as RatePlansIcon } from 'assets/icons/side-menu/rate-plans.svg';
import { ReactComponent as ReportsIcon } from 'assets/icons/side-menu/reports.svg';
import { ReactComponent as EdrIcon } from 'assets/icons/side-menu/edr.svg';
import { ReactComponent as MediaIcon } from 'assets/icons/side-menu/media.svg';
import { ReactComponent as SenderIdsIcon } from 'assets/icons/side-menu/senderid.svg';
import { ReactComponent as StopWordsIcon } from 'assets/icons/side-menu/stopwords.svg';
import { ReactComponent as TemplatesIcon } from 'assets/icons/side-menu/templates.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/side-menu/users.svg';
import { ReactComponent as AuditIcon } from 'assets/icons/side-menu/audit.svg';
import { ReactComponent as RobotIcon } from 'assets/icons/side-menu/robot.svg';
import { ReactComponent as IntegrationsIcon } from 'assets/icons/side-menu/integrations.svg';
import { ReactComponent as ApiAccessesIcons } from 'assets/icons/side-menu/api-accesses.svg';
import { ReactComponent as EnvelopeIcon } from 'assets/icons/side-menu/envelope.svg';
import { ReactComponent as VoiceIcon } from 'assets/icons/side-menu/voice.svg';

import { IS_LANC_HOST, IS_DEV_HOST } from './hosts';

export const VIEW_PERMISSIONS_DICTIONARY = {
  '/campaigns': 'broadcasts-view',
  '/recipients': 'contactdb-contacts-view',
  '/recipients/import': 'contactdb-contacts-import',
  '/recipients/stop-words': 'optout-rules-view',
  '/conversations': 'conversations-view',
  '/conversations/chatbots': 'scenarios-view',
  '/finance': 'finance-payments-view',
  '/reports': 'reports-view',
  '/edrs': 'edrs-view',
  '/media': 'mediadb-media-view',
  '/media/sender-ids': 'sender-ids-view',
  '/media/content-templates': 'content-templates-view',
  '/media/whats-app-templates': 'whatsapp-templates-view',
  '/user-management': 'users-view',
  '/audit': 'audit-view',
};

export const MAIN_MENU = [
  {
    href: '/dashboard',
    label: 'MENU.DASHBOARD',
    icon: DashboardIcon,
  },
  {
    id: 'broadcasts',
    href: '/broadcasts',
    label: 'MENU.BROADCAST',
    icon: BroadcastsIcon,
    permissionKey: VIEW_PERMISSIONS_DICTIONARY['/campaigns'],
  },
  {
    id: 'subscribers',
    href: '/recipients',
    label: 'MENU.CONTACTS',
    icon: SubscribersIcon,
    permissionKey: VIEW_PERMISSIONS_DICTIONARY['/recipients'],
    subLinks: [
      {
        id: 'subscribers-subscribers',
        href: '/recipients',
        label: 'MENU.CONTACTS',
        icon: SubscribersIcon,
        permissionKey: VIEW_PERMISSIONS_DICTIONARY['/recipients'],
      },
      {
        id: 'subscribers-import',
        href: '/recipients/import',
        label: 'SCREEN_TITLES.RECIPIENTS_IMPORT',
        icon: ImportIcon,
        permissionKey: VIEW_PERMISSIONS_DICTIONARY['/recipients/import'],
      },
      {
        id: 'stop-words',
        href: '/recipients/stop-words',
        label: 'MENU.STOP_WORDS',
        icon: StopWordsIcon,
        permissionKey: VIEW_PERMISSIONS_DICTIONARY['/recipients/stop-words'],
      },
    ],
  },
  {
    id: 'conversations',
    href: '/conversations',
    label: 'MENU.CONVERSATIONS',
    icon: ConversationsIcon,
    permissionKey: VIEW_PERMISSIONS_DICTIONARY['/conversations'],
    subLinks: [
      {
        id: 'conversations-conversations',
        href: '/conversations',
        label: 'MENU.CONVERSATIONS',
        icon: ConversationsIcon,
        permissionKey: VIEW_PERMISSIONS_DICTIONARY['/conversations'],
      },
      {
        id: 'scenarios',
        href: '/conversations/chatbots',
        label: 'MENU.SCENARIOS',
        icon: RobotIcon,
        permissionKey: VIEW_PERMISSIONS_DICTIONARY['/conversations/chatbots'],
      },
    ],
  },
  {
    id: 'finance',
    href: '/finance',
    label: 'NEW_TRANSLATES.SCREEN_TITLES.FINANCE',
    icon: FinanceIcon,
    permissionKey: VIEW_PERMISSIONS_DICTIONARY['/finance'],
    subLinks: [
      {
        id: 'invoices',
        href: '/finance',
        label: 'NEW_TRANSLATES.SCREEN_TITLES.FINANCE',
        icon: FinanceDocumentsIcon,
        permissionKey: VIEW_PERMISSIONS_DICTIONARY['/finance'],
      },
      {
        id: 'services',
        href: '/services',
        label: 'NEW_TRANSLATES.SCREEN_TITLES.SERVICES',
        icon: RatePlansIcon,
      },
    ],
  },
  {
    id: 'statistics',
    href: '/reports',
    label: 'MENU.STATISTICS',
    icon: StatisticsIcon,
    permissionKey: VIEW_PERMISSIONS_DICTIONARY['/reports'],
    subLinks: [
      {
        id: 'reports',
        href: '/reports',
        label: 'MENU.REPORTS',
        icon: ReportsIcon,
        permissionKey: VIEW_PERMISSIONS_DICTIONARY['/reports'],
      },
      {
        id: 'statistics-edrs',
        href: '/edrs',
        label: 'MENU.EDRS',
        icon: EdrIcon,
        permissionKey: VIEW_PERMISSIONS_DICTIONARY['/edrs'],
      },
      {
        id: 'traffic-statistics',
        href: '/traffic-statistics',
        label: 'MENU.STATISTICS',
        icon: EdrIcon,
      },
    ],
  },
  {
    id: 'assets',
    href: '/media',
    label: 'MENU.ASSETS',
    icon: AssetsIcon,
    permissionKey: VIEW_PERMISSIONS_DICTIONARY['/media'],
    subLinks: [
      {
        id: 'media-assets',
        href: '/media',
        label: 'MENU.MEDIA',
        icon: MediaIcon,
        permissionKey: VIEW_PERMISSIONS_DICTIONARY['/media'],
      },
      {
        id: 'media-sender-ids',
        href: '/media/sender-ids',
        label: 'MENU.SENDER_IDS',
        icon: SenderIdsIcon,
        permissionKey: VIEW_PERMISSIONS_DICTIONARY['/media/sender-ids'],
      },
      {
        id: 'content-templates',
        href: '/media/content-templates',
        label: 'MENU.CONTENT_TEMPLATES',
        icon: TemplatesIcon,
        permissionKey: VIEW_PERMISSIONS_DICTIONARY['/media/content-templates'],
      },
      {
        id: 'whats-app-templates',
        href: '/media/whats-app-templates',
        label: 'MENU.WHATSAPP_TEMPLATES',
        icon: TemplatesIcon,
        permissionKey: VIEW_PERMISSIONS_DICTIONARY['/media/whats-app-templates'],
      },
      {
        id: 'dialogue-templates',
        href: '/media/dialogue-templates',
        label: 'NEW_TRANSLATES.SCREEN_TITLES.DIALOGUE_TEMPLATES',
        icon: TemplatesIcon,
      },
    ],
  },
  {
    id: 'user-management',
    href: '/user-management',
    label: 'NEW_TRANSLATES.SCREEN_TITLES.USERS',
    icon: UsersIcon,
    permissionKey: VIEW_PERMISSIONS_DICTIONARY['/user-management'],
  },
  {
    id: 'audit',
    href: '/audit',
    label: 'MENU.AUDIT',
    icon: AuditIcon,
    permissionKey: VIEW_PERMISSIONS_DICTIONARY['/audit'],
  },
  {
    id: 'api-accesses',
    href: '/api-accesses',
    label: 'NEW_TRANSLATES.SCREEN_TITLES.API_ACCESSES',
    icon: ApiAccessesIcons,
    subLinks: [],
  },
];

const subLinks = [
  {
    id: '2fa-sms',
    href: '/2fa-sms',
    label: 'NEW_TRANSLATES.SCREEN_TITLES.2FA_SMS',
    icon: EnvelopeIcon,
  },
];

if (IS_LANC_HOST || IS_DEV_HOST) {
  subLinks.push({
    id: '2fa-voice',
    href: '/2fa-voice',
    label: 'NEW_TRANSLATES.SCREEN_TITLES.2FA_VOICE',
    icon: VoiceIcon,
  });
}

MAIN_MENU.push({
  id: '2fa-service',
  href: '/2fa-sms',
  label: '2FA service',
  icon: IntegrationsIcon,
  subLinks,
});

export const SUB_MENU = [
  {
    id: 'accounts',
    href: '/account',
    label: 'MENU.MY_ACCOUNT',
    icon: NoAvatarIcon,
  },
];
